import React from "react"
import "./BlueBackground.scss"
const imgUrlBackground = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/title_pattern.svg`
const imgUrlUser = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/Careers/Icon/user.svg`
const imgUrlDate = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/Careers/Icon/calendar.svg`

const BlueBackground = props => {
  const {
    jobName,
    moreInfo,
    quantity,
    dateTime,
    // author,
    classCustom,
    // blogInfo,
    applyLink
  } = props
  return (
    <div
      className={`blue-background ${classCustom}`}
      style={{ backgroundImage: `url(${imgUrlBackground})` }}
    >
      <div className="blue-background__text wrapper">
        <h1 className="h1-text fix-orphan black">{jobName}</h1>
        {moreInfo && (
          <div className="career-apply">
            <div className="base-info-job">
              <span
                className="sprite-career-icon sprite-career-icon__user"
                style={{ backgroundImage: `url(${imgUrlUser})` }}
              />{" "}
              {quantity && (
                <span className="mt-2 mr-4 distance">{quantity}</span>
              )}
              <span
                className="sprite-career-icon sprite-career-icon__date"
                style={{ backgroundImage: `url(${imgUrlDate})` }}
              />
              {"  "}
              <span className="mt-2 distance">{dateTime}</span>
            </div>
            {applyLink && (
              <div className="base-info-job career-apply__button">
                <button onClick={() => window.open(applyLink)}>
                  Apply Now
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default BlueBackground
