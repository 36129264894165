import React from "react"
import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
// import CustomModal from "../../components/Modal/CustomModal.js"
import "./SalaryAndBenefits.scss"

const salaryAndBenefitsData = [
  "Attractive salary based on your performance.",
  "Annual 13th month salary.",
  "Dedication bonus goes up to 40% monthly salary.",
  "Project bonus will be paid twice a year.",
  "Premium healthcare insurance, Annual health checks up.",
  "Breakfast are available at office in early morning and snack in late afternoon.",
  "Happy Lunch every two weeks.",
  "Team building allowance.",
  "Annual Company team building trip.",
  "Clubs (Football, Badminton, Swimming, Chess, Music band).",
  "Exclusive training programs for all staff in technical, soft skills and process.",
  "Outdoor training every 6 months.",
  "English class.",
  "Technical clubs.",
  "Marriage allowance, having newborn baby allowance, bereavement allowance.",
]

const SalaryAndBenefits = ({ benefits = salaryAndBenefitsData }) => {
  // const [showModal, setShowModal] = useState(false)
  // const [, setData] = useState({})

  // const toggleModal = item => {
  //   setShowModal(!showModal)
  //   setData(item)
  // }
  return (
    <div className="salary_and_benefits">
      <div className="wrapper">
        <h2 className="h2-text fix-orphan black heading">
          Monthly Salary and Benefits
        </h2>
        <div className="salary_benefits_data margin-top">
          <ul>
            {benefits &&
              benefits.map((item, index) => {
                return (
                  <HandleOrphan>
                    <li
                      className="salary_benefits_data__item h4-text fix-orphan"
                      key={index}
                    >
                      {item}
                    </li>
                  </HandleOrphan>
                )
              })}
          </ul>
        </div>
        {/* -------------------------Disable apply button--------------------- */}
        {/* <button
          className="custom-btn btn-apply btn-salary-benefits"
          onClick={() => toggleModal()}
        >
          apply now
        </button>
        <CustomModal showModal={showModal} hide={toggleModal} /> */}
      </div>
    </div>
  )
}

export default SalaryAndBenefits
